import { RecipeTaskStatus } from "@/api/sdk"
import AddTagInput from "@/components/Explore/Gallery/GalleryFeatures/AddTagInput"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import IconButton from "@/components/IconButton"
import TextCopy from "@/components/TextCopy"
import { ArrowDownIcon, EditIcon, SaveIcon, XIcon } from "@/components/shared/icons"
import { useOutsideClick } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useUpdateTaskMutation } from "@/queries"
import {
  useTagsWorkflowMutation,
  useUnTagsWorkflowMutation,
  useWorkspaceWorkflowDetailQuery,
} from "@/queries/workspace/workflow"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import ProgressbarTask from "../../Recipes/RecipeDetail/ProgressbarTask"
import { WorkflowPublishType, convertInfinityToZero } from "../WorkflowDetailStep/WorkFlowDetailStep"
import { TaskProgressSocketEvent } from "@/providers/ProgressingProvider"

interface WorkflowInfoProps {
  workflowState: WorkflowPublishType
  onHandleAutoSaveDraft: (description?: string) => void
  mode?: "draft" | "published"
  progressData?: TaskProgressSocketEvent | null
}

const WorkflowInfo = ({ workflowState, onHandleAutoSaveDraft, mode = "draft", progressData }: WorkflowInfoProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  const [value, setValue] = useState(workflowState.description)
  const [isEdit, setIsEdit] = useState(true)
  const [isEditTag, setIsEditTag] = useState(true)
  const { user } = useAuth()
  const isDisabled = workflowState.creator?.uid !== user?.uid
  const workflowKey = useWorkspaceWorkflowDetailQuery.getKey({ workflowId: workflowState.id })
  const tagRef = useRef<HTMLDivElement>(null)
  const [isFocus, setIsFocus] = useState(false)

  useEffect(() => {
    if (mode === "published") {
      setIsEdit(false)
      setIsEditTag(false)
    }
  }, [mode])

  useEffect(() => {
    const timer = setTimeout(() => {
      if (value === workflowState.description) return
      onHandleAutoSaveDraft(value)
    }, 2500)

    return () => clearTimeout(timer)
  }, [value])

  const [tags, setTags] = useState<
    {
      id: number
      name: string
    }[]
  >([])

  useEffect(() => {
    if (workflowState.tags) {
      setTags(workflowState.tags)
    }
    if (workflowState.description) {
      setValue(workflowState.description)
    }
  }, [workflowState])

  const [isShowInfo, setIsShowInfo] = useState(true)

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (!textareaRef.current) return
    textareaRef.current.style.height = "inherit"
    // Set height
    textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, 60)}px`
  }, [value])

  const { mutate: mutateTagsWorkflow } = useTagsWorkflowMutation({
    onMutate: async ({ tagIds, workflowIds }) => {
      const getWorkflow = qc.getQueryData(workflowKey)

      if (getWorkflow) {
        qc.setQueryData(workflowKey, {
          ...getWorkflow,
          tags: [
            ...getWorkflow.tags,
            ...tagIds.map(tagId => ({
              id: tagId,
              name: tags.find(t => t.id === tagId)?.name ?? "",
            })),
          ],
        })
      }
    },
  })
  const { mutate: mutateUnTagsWorkflow } = useUnTagsWorkflowMutation({
    onMutate: async ({ tagIds, workflowIds }) => {
      const getWorkflow = qc.getQueryData(workflowKey)

      if (getWorkflow) {
        qc.setQueryData(workflowKey, {
          ...getWorkflow,
          tags: getWorkflow.tags.filter(t => !tagIds.includes(t.id)),
        })
      }
    },
  })
  const qc = useQueryClient()

  const { mutate: mutateUpdateTask } = useUpdateTaskMutation({
    onSuccess: () => {
      const getWorkflow = qc.getQueryData(workflowKey)

      if (getWorkflow) {
        qc.setQueryData(workflowKey, {
          ...getWorkflow,
          description: value ?? "",
        })
      }
    },
  })

  const handleUpdateTask = async () => {
    if (mode === "published") {
      setIsEdit(prev => !prev)
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        action: "Edit Macro Description",
        macro_id: workflowState.id,
        macro_description: workflowState.description ?? "",
        macro_status: workflowState.status,
      },
    })

    if (isEdit) {
      if (mode === "draft" || workflowState.description === value) return

      mutateUpdateTask({
        taskId: workflowState.id,
        data: {
          description: value ?? "",
        },
      })

      return
    }

    setTimeout(() => {
      if (textareaRef.current) {
        textareaRef.current.focus()
      }
    }, 100)
  }

  useOutsideClick({
    ref: tagRef,
    enabled: isEditTag && !isFocus,
    handler: () => {
      if (workflowState.status === RecipeTaskStatus.DRAFT) return
      setIsEditTag(false)
    },
  })

  let stepCompleted = ((progressData?.node ?? 1) - 1) / (progressData?.total_nodes ?? 1)
  const totalCurrentStepCompleted = stepCompleted * (1 / (progressData?.total_steps ?? 1))
  const totalPreviousStepsCompleted = ((progressData?.step ?? 1) - 1) / (progressData?.total_steps ?? 1)

  let totalStepCompleted = totalPreviousStepsCompleted + totalCurrentStepCompleted

  stepCompleted = Math.round(stepCompleted * 100)
  totalStepCompleted = Math.round(totalStepCompleted * 100)

  if (!user && workflowState.creator?.uid === "anonymous") return null

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      layout
      transition={{ duration: 0.35, delay: 0.4 }}
      className="relative py-4"
    >
      {(workflowState.status === RecipeTaskStatus.QUEUED || workflowState.status === RecipeTaskStatus.RUNNING) && (
        <ProgressbarTask
          isLoading={workflowState.id !== progressData?.task_id}
          className="mb-4"
          progress={Math.min(convertInfinityToZero(totalStepCompleted), 100)}
          description={`Generating process is in step ${progressData?.step ?? workflowState.params?.length ?? 0}/${
            progressData?.total_steps ?? 0
          }. Step ${progressData?.step ?? 0} is running at ${Math.max(convertInfinityToZero(stepCompleted), 100)}%`}
        />
      )}
      <AnimatePresence initial={false}>
        {isShowInfo && (
          <motion.div
            className="overflow-hidden flex flex-col workflow-info"
            initial={{ height: 0 }}
            animate={{ height: isShowInfo ? "auto" : 0 }}
            exit={{ height: 0 }}
          >
            <div className="flex-1 space-y-4">
              {mode === "published" && (
                <div className="font-semibold w-80">
                  <UserCreated
                    username={workflowState.creator?.username ?? ""}
                    name={workflowState.creator?.name ?? ""}
                    picture={workflowState.creator?.picture ?? ""}
                    createdAt={workflowState.createdAt ?? ""}
                  />
                </div>
              )}
              <div ref={tagRef}>
                <div className="mb-2 flex items-center">
                  <p className="text-atherGray-500 text-xs font-semibold flex-1">TAGS</p>
                  {!isDisabled && mode === "published" && (
                    <IconButton onClick={() => setIsEditTag(prev => !prev)} colorScheme="transparent" className="p-0">
                      {!isEditTag ? (
                        <EditIcon className="text-atherGray-500" />
                      ) : (
                        <SaveIcon className="text-atherGray-500" />
                      )}
                    </IconButton>
                  )}
                </div>
                <div
                  className={classNames("bg-black relative rounded-lg p-2 flex flex-wrap gap-1 text-sm", {
                    "bg-transparent py-0 px-0": !isEditTag,
                  })}
                >
                  {tags.length > 0
                    ? tags.map(tag => (
                        <div
                          key={tag.id}
                          className="flex items-center px-2 py-0.5 border rounded-lg border-atherGray-800"
                        >
                          <p className="mr-1 text-atherGray-300">{tag.name}</p>
                          {!isEditTag ? (
                            <TextCopy value={tag.name} />
                          ) : (
                            <div
                              className="cursor-pointer flex items-center justify-center"
                              onClick={() => {
                                googleAnalytics.handleCategoryEvent({
                                  action: "click",
                                  params: {
                                    action: "Remove Macro Tag",
                                    macro_id: workflowState.id,
                                    macro_description: workflowState.description ?? "",
                                    macro_status: workflowState.status,
                                    tag_id: tag.id,
                                    tag_name: tag.name,
                                  },
                                })

                                mutateUnTagsWorkflow({
                                  tagIds: [tag.id],
                                  workflowIds: [workflowState.id],
                                })
                                setTags(tags.filter(t => t.id !== tag.id))
                              }}
                            >
                              <XIcon width={10} height={10} className="text-atherGray-300" />
                            </div>
                          )}
                        </div>
                      ))
                    : !isEditTag && <p className="text-atherGray-500">No tags</p>}
                  {isEditTag && (
                    <AddTagInput
                      onFocus={focus => setIsFocus(focus)}
                      onTag={tag => {
                        mutateTagsWorkflow({
                          tagIds: [tag.id],
                          workflowIds: [workflowState.id],
                        })

                        googleAnalytics.handleCategoryEvent({
                          action: "click",
                          params: {
                            action: "Add Macro Tag",
                            macro_id: workflowState.id,
                            macro_description: workflowState.description ?? "",
                            macro_status: workflowState.status,
                          },
                        })
                      }}
                      tags={tags}
                      setTags={setTags}
                      className="text-sm bg-transparent text-atherGray-300"
                    />
                  )}
                </div>
              </div>
              <div>
                <div className="mb-2 flex items-center">
                  <p className="text-atherGray-500 text-xs font-semibold flex-1">DESCRIPTION</p>
                  {!isDisabled && mode === "published" && (
                    <IconButton
                      onClick={() => {
                        handleUpdateTask()
                      }}
                      colorScheme="transparent"
                      className="p-0"
                    >
                      {!isEdit ? (
                        <EditIcon className="text-atherGray-500" />
                      ) : (
                        <SaveIcon className="text-atherGray-500" />
                      )}
                    </IconButton>
                  )}
                </div>
                <textarea
                  ref={textareaRef}
                  maxLength={500}
                  disabled={!isEdit}
                  readOnly={!isEdit}
                  value={value}
                  onBlur={() => {
                    handleUpdateTask()
                  }}
                  onFocus={e =>
                    e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
                  }
                  placeholder={isEdit ? "Describe your macro" : "No description"}
                  className={classNames(
                    "bg-black resize-none text-sm w-full rounded-lg p-2 placeholder:text-atherGray-500 text-atherGray-0",
                    {
                      "bg-transparent py-0 px-0": !isEdit,
                    },
                  )}
                  onChange={e => {
                    setValue(e.target.value)
                  }}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <IconButton
        colorScheme="transparent"
        className="mx-auto space-x-2 p-2"
        onClick={() => {
          googleAnalytics.handleCategoryEvent({
            action: "click",
            params: {
              action: "Toggle Macro Info",
              macro_id: workflowState.id,
              macro_description: workflowState.description ?? "",
              macro_status: workflowState.status,
            },
          })

          setIsShowInfo(prev => !prev)
        }}
      >
        <span
          className={classNames("", {
            "rotate-0": !isShowInfo,
            "rotate-180": isShowInfo,
          })}
        >
          <ArrowDownIcon className={"text-atherGray-300"} width={12} height={12} />
        </span>
      </IconButton>
    </motion.div>
  )
}

export default WorkflowInfo
