import { ContentModerationRating, RecipeTask, RecipeTaskStatus } from "@/api/sdk"
import ImageWebpComponent from "@/components/ImageWebpComponent"
import { FlowIcon, FlowSquareIcon, ScienceIcon } from "@/components/shared/icons"
import { useRaisedShadow } from "@/hooks/useRaisedShadow"
import { googleAnalytics } from "@/lib/gtag"
import { TaskProgressSocketEvent } from "@/providers/ProgressingProvider"
import classNames from "classnames"
import format from "date-fns/format"
import { PanInfo, Reorder, motion, useDragControls, useMotionValue } from "framer-motion"
import { memo, useState } from "react"
import { useInView } from "react-intersection-observer"
import LoadingIcon from "../../LoadingIcon"
import Skeleton from "../../Skeleton"
import ProgressbarTask from "../Recipes/RecipeDetail/ProgressbarTask"
import { convertInfinityToZero } from "../Workflows/WorkflowDetailStep/WorkFlowDetailStep"
import ActionButtonsTask from "./Action/ActionButtonsTask"
import NsfwBlurImage from "@/components/NsfwBlurImage"

const GridItem = memo(
  ({
    recipe,
    onDragEnd,
    isDraggable,
    onDelete,
    onCancel,
    isLoading,
    onRestart,
    onCreate,
    onView,
    progressData,
  }: {
    recipe: RecipeTask
    progressData?: TaskProgressSocketEvent
    onDragEnd: (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => void
    isDraggable?: boolean
    onDelete: () => void
    isLoading: boolean
    onRestart: () => void
    onCancel: () => void
    onCreate: () => void
    onView: () => void
  }) => {
    const y = useMotionValue(0)
    const boxShadow = useRaisedShadow(y)
    const dragControls = useDragControls()
    const [imageLoaded, setImageLoaded] = useState(false)
    const { ref, inView } = useInView({
      threshold: 0,
      rootMargin: "800px 0px 800px 0px",
    })

    const [isShow, setIsShow] = useState(
      recipe.images?.[0]?.moderationRating === ContentModerationRating.HardcoreNsfw ||
        recipe.images?.[0]?.moderationRating === ContentModerationRating.SemiNsfw,
    )
    let stepCompleted = ((progressData?.node ?? 1) - 1) / (progressData?.total_nodes ?? 1)
    const totalCurrentStepCompleted = stepCompleted * (1 / (progressData?.total_steps ?? 1))
    const totalPreviousStepsCompleted = ((progressData?.step ?? 1) - 1) / (progressData?.total_steps ?? 1)

    let totalStepCompleted = totalPreviousStepsCompleted + totalCurrentStepCompleted

    stepCompleted = Math.round(stepCompleted * 100)
    totalStepCompleted = Math.round(totalStepCompleted * 100)

    return (
      <Reorder.Item
        as="div"
        key={recipe.id}
        id={recipe.id}
        value={recipe}
        style={{ y, boxShadow }}
        dragListener={false}
        dragControls={isDraggable ? dragControls : undefined}
        onPointerDown={e => (isDraggable ? dragControls.start(e) : undefined)}
        onDragStart={e => {
          e.preventDefault()
        }}
        onDragEnd={onDragEnd}
        onClick={e => {
          e.stopPropagation()
          googleAnalytics.event({
            action: "click",
            category: "agent_scheduler",
            label: "view_details",
            params: {
              recipeId: recipe.recipeId,
              taskId: recipe.id,
              status: recipe.status,
            },
          })
          onView()
        }}
        className="rounded-md hover:bg-atherGray-850 cursor-pointer flex flex-col relative max-h-full whitespace-normal w-full shadow-md bg-atherGray-900"
      >
        <div
          className="relative min-h-[2rem] p-2 flex flex-col w-full"
          style={{
            flex: "0 0 auto",
          }}
        >
          <div className="flex items-start mb-2">
            <div className="flex-1 line-clamp-1">
              <div className="flex flex-col">
                <div className="text-xs flex items-center">
                  {recipe.sdWorkflow ? (
                    <FlowSquareIcon className="w-3 h-3 mr-1" />
                  ) : recipe.recipeId === "recipe-to-recipe" ? (
                    <FlowIcon className="w-3 h-3 mr-1" />
                  ) : (
                    <ScienceIcon className="w-3 h-3 mr-1" />
                  )}
                  {recipe.sdWorkflow
                    ? recipe.sdWorkflow.name
                    : recipe.recipeId === "recipe-to-recipe"
                      ? recipe.name
                      : recipe.recipeId}
                </div>
                {recipe.executionDuration ? (
                  <p className={"mt-1 text-xs text-atherGray-400"}>
                    (Running time: {(recipe.executionDuration / 1000).toFixed(1)}s)
                  </p>
                ) : null}
              </div>
            </div>
            {isLoading ? (
              <div>
                <LoadingIcon width={10} height={10} />
              </div>
            ) : (
              <ActionButtonsTask
                isLoading={isLoading}
                onCancel={onCancel}
                onCreate={onCreate}
                onDelete={onDelete}
                onRestart={onRestart}
                onView={onView}
                recipe={recipe}
              />
            )}
          </div>
          {(recipe.status === RecipeTaskStatus.QUEUED || recipe.status === RecipeTaskStatus.RUNNING) && (
            <ProgressbarTask
              isLoading={!progressData || recipe.id !== progressData?.task_id}
              className="mb-4"
              progress={Math.min(convertInfinityToZero(totalStepCompleted), 100)}
              description={
                recipe.recipeId === "recipe-to-recipe"
                  ? `Generating process is in step ${progressData?.step ?? 0}/${
                      progressData?.total_steps ?? (recipe.params as any).length
                    }. Step ${progressData?.step ?? 0} is running at ${Math.max(convertInfinityToZero(stepCompleted), 100)}%`
                  : `Generating ${Math.min(convertInfinityToZero(totalStepCompleted), 100)}%`
              }
            />
          )}
        </div>
        <div className="p-2 w-full" ref={ref}>
          {recipe.status !== "CANCELED" && recipe.status !== "FAILED" && (
            <Skeleton
              className={classNames(
                "relative group overflow-hidden w-full rounded-lg mb-2 bg-atherGray-950 flex items-center justify-center h-[10rem]",
                {
                  "min-h-[15rem]": !imageLoaded || recipe.status === "RUNNING" || recipe.status === "QUEUED",
                  hidden: recipe.status === "COMPLETED" && recipe.images?.length === 0,
                },
              )}
              isLoaded={imageLoaded}
              blurHash={undefined}
            >
              {inView && (
                <ImageWebpComponent
                  className={classNames("max-h-[10rem]", {
                    "blur-2xl": isShow,
                  })}
                  onLoad={() => setImageLoaded(true)}
                  src={recipe.images && recipe.images?.length > 0 ? recipe.images[0].url : ""}
                  alt=""
                />
              )}

              {recipe.images && recipe.images?.length - 1 > 0 && inView && (
                <div className="absolute bottom-2 right-2 text-xs bg-atherGray-0 text-atherGray-900 rounded-full font-semibold px-1 py-0.5">
                  <p>
                    +{recipe.images.length - 1} {recipe.images.length > 1 ? "images" : "image"}
                  </p>
                </div>
              )}
              {recipe.images?.length === 0 && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className="absolute top-0 left-0 overflow-hidden p-2 z-[2] w-full"
                >
                  <div className="flex justify-center rounded-xl items-center">
                    <LoadingIcon width={20} height={20} />
                  </div>
                </motion.div>
              )}

              {inView &&
              (recipe.images?.[0]?.moderationRating === ContentModerationRating.HardcoreNsfw ||
                recipe.images?.[0]?.moderationRating === ContentModerationRating.SemiNsfw) ? (
                <NsfwBlurImage
                  isShow={isShow}
                  moderationRating={recipe.images?.[0]?.moderationRating}
                  onToggleShow={setIsShow}
                  gaEvent={{
                    tab_name: "backlog",
                    params: {
                      task_id: recipe.id,
                      recipe_id: recipe.recipeId,
                    },
                  }}
                />
              ) : null}
            </Skeleton>
          )}
          <p className="line-clamp-2 text-sm text-atherGray-100">{recipe.prompt ?? recipe.name}</p>
          <div className="flex items-center mt-2">
            <p className="flex-1 text-xs text-atherGray-300">
              {format(new Date(recipe.createdAt), "dd/MM/yyyy HH:mm")}
            </p>
          </div>
        </div>
      </Reorder.Item>
    )
  },
)

export default GridItem
