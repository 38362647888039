import { ListRecipeTasksResponse, RecipeTaskWithFullImages } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import LoadingIcon from "@/components/LoadingIcon"
import { COMFY_RECIPE_ID } from "@/components/Tools/ComfyUIRecipe/ComfyUIRecipeBuilder/ComfyUIDetail"
import { TRY_STYLE_RECIPE } from "@/components/Tools/Style/StyleDetail/StyleDetail"
import { FlowIcon, OpenNewTabIcon, ParagraphBulletsIcon, ScienceIcon, XIcon } from "@/components/shared/icons"
import { useScreen, useToast } from "@/hooks"
import { cn } from "@/lib/utils"
import { TaskProgressSocketEvent, useProgressingContext } from "@/providers/ProgressingProvider"
import { useAuth } from "@/providers/authContext"
import { useCancelTaskMutation } from "@/queries"
import { useGetOngoingTaskQuery } from "@/queries/task/useGetTaskInfiniteQuery"
import { useTempTaskStoreV2 } from "@/stores"
import { useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import Link from "next/link"
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { RecipeSocketData } from "../Recipes/RecipeDetail/ListRecipes"
import ProgressbarTask from "../Recipes/RecipeDetail/ProgressbarTask"
import { convertInfinityToZero } from "../Workflows/WorkflowDetailStep/WorkFlowDetailStep"
import useCustomRouter from "@/hooks/useCustomRouter"
import Popover from "@/components/Popover"

const ItemTaskTrackerProgress = ({
  task,
  progressData,
  hiddenProgressbar,
}: {
  task: RecipeTaskWithFullImages
  progressData?: TaskProgressSocketEvent
  hiddenProgressbar?: boolean
}) => {
  const toast = useToast()
  const { openNewTab } = useCustomRouter()
  const router = useCustomRouter()
  const qc = useQueryClient()

  const { mutate: mutateCancelTask, isPending } = useCancelTaskMutation({
    onSuccess: async () => {
      toast({
        title: "Task is canceled",
        status: "success",
      })
    },
  })

  const handleCancelTask = () => {
    mutateCancelTask(task.id)
  }

  const handleOpenTask = () => {
    if (task.recipeId === "recipe-to-recipe") {
      setTimeout(() => {
        openNewTab(`/workspace/macros/${task.id}`)
      }, 1)
      return
    }

    if (task.recipeId === TRY_STYLE_RECIPE) {
      setTimeout(() => {
        openNewTab(`/workspace/tools/styles/${task.params?.["style"]}`)
      }, 1)
      return
    }

    if (task.recipeId === COMFY_RECIPE_ID) {
      setTimeout(() => {
        openNewTab(`/workspace/tools/comfyui/${task.sdWorkflowId}`)
      }, 1)

      return
    }

    setTimeout(() => {
      openNewTab(`/workspace/tools/recipes/${task.recipeId}`)
    }, 1)
  }

  let stepCompleted = ((progressData?.node ?? 1) - 1) / (progressData?.total_nodes ?? 1)
  const totalCurrentStepCompleted = stepCompleted * (1 / (progressData?.total_steps ?? 1))
  const totalPreviousStepsCompleted = ((progressData?.step ?? 1) - 1) / (progressData?.total_steps ?? 1)

  let totalStepCompleted = totalPreviousStepsCompleted + totalCurrentStepCompleted

  stepCompleted = Math.round(stepCompleted * 100)
  totalStepCompleted = Math.round(totalStepCompleted * 100)

  return (
    <div className="flex items-start overflow-hidden py-2">
      <div className="mr-2 bg-atherGray-850 p-2 rounded-lg">
        {task.recipeId === "recipe-to-recipe" ? (
          <FlowIcon width={16} height={16} />
        ) : (
          <ScienceIcon width={16} height={16} />
        )}
      </div>
      <div className="flex-1 overflow-hidden">
        <div className="flex items-center overflow-hidden">
          <p className="uppercase text-[0.65rem] text-atherGray-500 font-semibold truncate flex-1">
            {task.recipeId === "recipe-to-recipe" ? "MACRO" : "RECIPE"}: {task.name ?? task.id}
          </p>
          <span className="flex items-center space-x-1">
            <IconButton onClick={handleOpenTask} colorScheme="transparent" className="min-h-0 p-0.5">
              <OpenNewTabIcon width={12} height={12} />
            </IconButton>
            <IconButton
              isLoading={isPending}
              onClick={handleCancelTask}
              colorScheme="transparent"
              className={classNames("min-h-0 p-0.5", {
                "bg-atherGray-900": isPending,
              })}
            >
              <XIcon width={12} height={12} />
            </IconButton>
          </span>
        </div>
        <p className="font-semibold text-xs truncate">{task.name ?? task.id}</p>
        {!hiddenProgressbar && (
          <>
            <div className="flex items-center">
              <p className="text-[0.65rem] mr-1 font-semibold w-8">
                {!progressData || task.id !== progressData?.task_id
                  ? "...%"
                  : `${Math.min(convertInfinityToZero(totalStepCompleted), 100)}%`}
              </p>

              <ProgressbarTask
                isLoading={!progressData || task.id !== progressData?.task_id}
                className="flex-1"
                progress={Math.min(convertInfinityToZero(totalStepCompleted), 100)}
              />
            </div>
            {task.recipeId === "recipe-to-recipe" ? (
              <p className="text-xs text-atherGray-300 mt-4">
                Generating process is in step {progressData?.step ?? 1}/
                {progressData?.total_steps ?? (task.params as any).length} <br />
                Step {progressData?.step ?? 1} is running at {Math.max(convertInfinityToZero(stepCompleted), 100)}%
              </p>
            ) : null}
          </>
        )}
      </div>
    </div>
  )
}

interface TaskTrackerProps {
  menuListClassname?: string
  className?: string
  triggerClickoutside?: boolean
  onTriggerClose?(): void
}

const TaskTracker = ({ menuListClassname, className, triggerClickoutside, onTriggerClose }: TaskTrackerProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  const { width } = useScreen()
  const isMobile = useMemo(() => width < 768, [width])

  const { socketRef, socketConnected, user, loading } = useAuth()
  const { tasksProgress, setTasksProgress } = useProgressingContext()
  const tempTask = useTempTaskStoreV2(state => state.tempTask)
  const [tasks, setTasks] = useState<RecipeTaskWithFullImages[]>([])

  const qc = useQueryClient()

  useEffect(() => {
    if (isMobile) return
    if (!triggerClickoutside || !isOpen) return

    setIsOpen(false)
    onTriggerClose?.()
  }, [triggerClickoutside, onTriggerClose, isOpen, isMobile])

  const { data: onGoingRecipes } = useGetOngoingTaskQuery({
    variables: {
      taskIds: user ? undefined : tempTask?.map(t => t.id),
    },
    enabled: !loading && !isMobile,
  })

  useEffect(() => {
    const data = [...(onGoingRecipes?.running ?? []), ...(onGoingRecipes?.queued ?? [])]
      .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      .filter(i => i.recipeType !== "turbo") as RecipeTaskWithFullImages[]

    setTasks(data)
  }, [onGoingRecipes])

  const refreshProgress = useCallback(async () => {
    qc.invalidateQueries({
      queryKey: [
        "board-view-task",
        {
          statuses: ["RUNNING"],
        },
      ],
    })
    qc.invalidateQueries({
      queryKey: ["infinite-workspace-workflows"],
    })
  }, [qc])

  useEffect(() => {
    if (isMobile) return

    if (!socketConnected || !socketRef.current) return
    const socket = socketRef.current

    const onStartTask = (data: RecipeSocketData) => {
      if (data) {
        setTimeout(() => {
          qc.invalidateQueries({
            queryKey: [
              "board-view-task",
              {
                statuses: ["QUEUED"],
              },
            ],
          })

          refreshProgress()
        }, 500)
      }
    }

    const onCompleteTask = (data: RecipeSocketData) => {
      const onGoingKey = useGetOngoingTaskQuery.getKey()
      const ongoingTasksEntities = qc.getQueriesData<ListRecipeTasksResponse>({ queryKey: onGoingKey })

      if (data) {
        if (!tasks.map(i => i.id).includes(data.id)) return

        if (data.resultImages.length > 0) {
          qc.invalidateQueries({
            queryKey: [
              "board-view-task",
              {
                statuses: ["COMPLETED"],
              },
            ],
          })
        }

        setTimeout(() => {
          qc.invalidateQueries({
            queryKey: [
              "board-view-task",
              {
                statuses: ["CANCELED"],
              },
            ],
          })
          qc.invalidateQueries({
            queryKey: [
              "board-view-task",
              {
                statuses: ["FAILED"],
              },
            ],
          })

          qc.invalidateQueries({
            queryKey: ["infinite-workspace-creation-images"],
          })

          qc.invalidateQueries({
            queryKey: ["infinite-workspace-creation-images-not-auth"],
          })

          ongoingTasksEntities?.forEach(([key, list]) => {
            if (list) {
              const updateData = {
                ...list,
                queued: list.queued.filter(i => i.id !== data.id),
                running: list.running.filter(i => i.id !== data.id),
              }

              qc.setQueryData(key, updateData)
            }
          })

          refreshProgress()
          setTasks(prev => prev?.filter(p => p.id !== data.id) ?? [])
          setTasksProgress(prev => prev?.filter(p => p.task_id !== data.id) ?? [])
        }, 250)
      }
    }

    socket.on("recipe-task-start", async (data: RecipeSocketData) => {
      onStartTask(data)
    })

    socket.on("recipe-task-complete", async (data: RecipeSocketData) => {
      onCompleteTask(data)
    })

    return () => {
      socket?.off("recipe-task-start", onStartTask)
      socket?.off("recipe-task-complete", onCompleteTask)
    }
  }, [socketConnected, refreshProgress, setTasksProgress, socketRef, qc, tasks.length])

  const isLoading = useMemo(() => !loading && tasks.length > 0, [tasks, loading])

  if (isMobile) return null

  return (
    <div ref={ref} className={cn("relative", className)}>
      <Popover
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        trigger={
          <div>
            {isLoading && (
              <div
                className={
                  "absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex size-[5rem] justify-center items-center overflow-hidden"
                }
              >
                <LoadingIcon width={40} height={40} />
              </div>
            )}
            <IconButton colorScheme={isOpen ? "primary" : "transparent"} className="p-1 min-h-0" title="Task tracker">
              <ParagraphBulletsIcon
                width={20}
                height={20}
                className={classNames("text-atherGray-300", {
                  "text-atherGray-0": isLoading,
                })}
              />
            </IconButton>
          </div>
        }
      >
        <div className={cn("w-[20rem] md:w-[24rem] z-20 overflow-hidden shadow-xl", menuListClassname)}>
          <div className="py-4 flex flex-col bg-atherGray-900 border-[1px] border-[#323230] overflow-hidden rounded-2xl">
            <p className="font-semibold mb-2 px-4">Tasks Tracker ({tasks.length ?? 0})</p>
            <div className=" px-4 flex flex-col divide-y divide-atherGray-800 overflow-auto min-h-[5rem] max-h-[60vh]">
              {tasks.length > 0 ? (
                tasks.map(i => (
                  <ItemTaskTrackerProgress
                    hiddenProgressbar={!user}
                    progressData={tasksProgress?.find(p => p.task_id === i.id)}
                    key={i.id}
                    task={i}
                  />
                ))
              ) : (
                <span className="text-center flex-1 flex items-center justify-center text-xs text-atherGray-300">
                  There is no task
                </span>
              )}
            </div>
            <div className="flex items-center justify-center px-4">
              <Link
                href="/my-creations"
                className="bg-atherPurple-500 w-full text-center rounded-lg p-2 font-semibold text-sm"
              >
                My Creations
              </Link>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  )
}

export default memo(TaskTracker)
